import { useColorMode as useMode, useLocalStorage } from '@vueuse/core'

/**
 * Manages the user's preferred color mode setting.
 */
export const useColorMode = () => {
  const mode = useMode({
    selector: 'html',
    attribute: 'data-color-mode',
    storageKey: 'studio:colorMode',
  })

  /**
   * Despite the standard web best practice being to default to the user's system preference, the
   * business requirement is for the dark theme to be the default.
   */
  const startInDarkMode = useLocalStorage('mfx-studio:startInDarkMode', true)

  if (startInDarkMode.value) {
    startInDarkMode.value = false
    mode.value = 'dark'
  }

  return mode
}
